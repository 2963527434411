/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";

$statColor: #225555;
$understated: #666;
$colWidthSm: calc(100% / 12);
$colWidthSix: calc(100% / 6);
$colWidthThree: calc(100% / 3);
$colWidthLg: calc(100% / 3);

a {
  cursor: pointer;
}

.ngClick {
  cursor: pointer;
}

.list-inline > li {
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.frontPageContent {
  max-width: 900px;
  background-color: #f0f0f0;
  border: 10px solid #ddd;
  border-radius: 5px;
  padding: 15px;

  .card {
    border: 3px solid #ccc;
  }

  .card-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  h1 {
    font-size: 50px;
    margin-top: -10px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 0;
  }

  .languageContainer {
    li + li:before {
      content: "|";
      padding: 0 4px;
    }
  }
}

.headingLink {
  float: right;
  font-size: 14px;
  margin-top: 4px;
  font-weight: normal;
}

footer {
  min-height: auto;
  padding: 10px 0;

  p, ul {
    margin-bottom: 0;
  }
}

#app.auth-shell-container {
  footer {
    background-color: transparent;
    background-image: none;
    text-align: center;
    color: #fff;
  }
}

.signInContainer {
  min-height: 100vh;
}

.signInFail {
  padding: 60px 20px 40px 20px;

  h1 {
    font-size: 20px;
  }
}

h1, h2, h3, h4, h5 {
  font-weight: bold;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 14px;
}

hr {
  border: 2px solid #ddd;
  opacity: 0.7;

  &.subDivider {
    border-style: dashed;
  }
}

.form-label {
  font-weight: bold;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .mainContainer, .containerManual {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .mainContainer, .containerManual {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .mainContainer, .containerManual {
    width: 1170px;
  }
}

.progress {
  margin-bottom: 1rem;
}

header {
  .languageContainer {
    position: relative;
    margin-right: 15px;
    margin-top: 0;

    .fa {
      margin-right: 6px;
    }

    li a, li strong {
      align-items: center;
      padding: 6px 8px;
      margin: 4px 0;
      font-weight: 600;
      text-decoration: none;

      &.active {
        cursor: default;
      }
    }
  }
}

.uiLogo {
  max-width: 170px;
  max-height: 40px;
}

.navbarLinks {
  display: flex;
  margin: 0 0 0 6px;

  a {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    margin: 4px 12px;
    font-weight: 600;
    text-decoration: none;
  }
}

.languageContainer {
  text-align: right;
  margin-top: 10px;

  .languageText {
    padding-right: 5px;
  }

  ul {
    display: inline-block;
    margin-bottom: 0;
  }
}

.appContainer {

  app-shell, app-anon-shell {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: auto;
  }

  .navbar {
    margin-bottom: 0 !important;
  }

  .mainContainer {
    flex: 1;
    padding: 25px;
    background-color: white;
    min-width: 95%;
    max-width: 95%;
  }

  @media (min-width: 768px) {
    .mainContainer, .containerManual {
      min-width: inherit;
      max-width: inherit;
    }
  }
}

.form-check {
  label, input[type="checkbox"], input[type="radio"] {
    cursor: pointer;
  }
}

.layoutThumbnail {
  max-width: 100%;
  max-height: 90px;
  vertical-align: middle;
  margin: 0 auto 3px auto;
  display: block;
}

.selectionContainer {
  h3 {
    margin-top: 5px;
  }

  .form-check-inline {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }

  .row {

    .thumbnailColumn {
      margin-bottom: 20px;

      .badge {
        font-size: 12.5px;
      }

      .thumbnailEntry {
        height: 100%;
        background-color: #f9f9f9;
        display: block;
        border: 1px solid #ddd;
        border-radius: 4px;

        label {
          cursor: pointer;
          display: block;
          height: 100%;
          padding: 1rem;
        }

        &.itemSelected {
          border-color: $success;
          background-color: lighten($success, 60%);
        }

        &:hover:not(.itemSelected):not(.itemDisabled) {
          background-color: #e9e9e9;
        }

        &.itemDisabled {
          cursor: default;
        }
      }
    }
  }

  .selectorIconContainer {
    font-size: 25px;
  }

  .selectedIcon {
    color: $success;
  }
}

.doubleColumned {
  column-count: 2;
}

.progressBarContainer {
  margin-bottom: 1rem;
}

.itemType {
  color: $understated;
}

.importantHighlight {
  color: red;
}

.totalsTable {
  clear: both;

  .fas {
    margin-left: 1px;
    margin-right: 2px;
  }

  li {
    display: inline-block;
    padding: 2px;
    width: 100%;
  }

  li:nth-child(even) {
    background-color: #eee;
    padding: 4px 2px;
  }

  .titleField {
    display: table-cell;
    width: 100%;
  }

  .numericColumn {
    color: $statColor;
    display: table-cell;
    font-weight: bold;
    white-space: nowrap;
  }
}

.alert {
  *:empty {
    display: none;
  }
}

.warningBanner {
  padding: 2px 10px;
  font-weight: bold;
  border-bottom: 1px solid black;
  flex-basis: 100%;

  &.envBanner {
    background-color: orange;

    span {
      background-color: peachpuff;
    }
  }

  &.blockedBanner {
    background-color: var(--bs-alert-bg);
    color: var(--bs-alert-color);
    padding: 10px 10px 5px 10px;
    font-size: 14px;
    border-bottom-color: var(--bs-alert-border-color);
  }

  &.masqBanner {
    background-color: #ddd;

    span {
      background-color: lemonchiffon;
    }

    .pull-right {
      font-weight: normal;
    }
  }
}

.borderedSpanBanner {
  span {
    display: inline-block;
    border: 1px solid black;
    padding: 1px 10px;
  }
}

.auth-shell-container {
  background: url(https://regalia.adnuntius.com/ui/bg-melbourne-2550x1440.jpg) no-repeat center center fixed;
  background-size: cover;

  &.nz {
    // sourced from https://en.wikipedia.org/wiki/File:New_Zealand_snow.jpg
    background-image: url(https://regalia.adnuntius.com/ui/New_Zealand_snow.jpg);
  }

  &.mtcook {
    // sourced from https://en.wikipedia.org/wiki/File:Mt_Cook,_NZ.jpg
    background-image: url(https://regalia.adnuntius.com/ui/Mt_Cook_NZ.jpg);
  }

  &.melbourne {
    background-image: url(https://regalia.adnuntius.com/ui/bg-melbourne-2550x1440.jpg);
  }

  &.oslo {
    // sourced from https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Frognerkilen_-_2011-09-07_at_21-36-46.jpg/1280px-Frognerkilen_-_2011-09-07_at_21-36-46.jpg
    background-image: url(https://regalia.adnuntius.com/ui/oslo.jpg);
  }

  &.fjell {
    background-image: url(https://regalia.adnuntius.com/label/fjell-ljom/bg-sign-in.jpg);
  }

  &.calt {
    background-image: url(https://regalia.adnuntius.com/label/calt/calt-back.png);
  }

  &.imml {
    background-image: url(https://regalia.adnuntius.com/label/imml/Adnuntius-IM-Background-1920x1080.jpg);
  }

  &.immb {
    background-image: url(https://regalia.adnuntius.com/label/immb/bgbg.jpg);
    .frontPageContent {
      background-color: transparent;
      border: none;
    }
  }

  &.hallingdolen {
    background-image: url(https://regalia.adnuntius.com/label/hallingdolen/bg.jpg)
  }

  &.infranken {
    background-image: url(https://regalia.adnuntius.com/label/infranken/bg-here-2.jpg);
  }

  &.specialties {
    background-image: url(https://regalia.adnuntius.com/label/specialties/adportalpagepic2.jpg);
  }

  &.meinestadt {
    background-image: url(https://regalia.adnuntius.com/label/meinestadt/background.jpg);
  }

  &.mediasquare {
    background-image: url(https://assets.adnuntius.com/vebZJuGog2P5uhDJnTCj9xiMv6FNVVNWoGQdir0jhG8.png);
  }

  &.oneid {
    background-image: none;
    background-color: #353631;
  }

  &.busad1 {
    background-image: url(https://regalia.adnuntius.com/label/businessad/b1.jpg);
  }

  &.busad2 {
    background-image: url(https://regalia.adnuntius.com/label/businessad/b2.jpg);
  }

  &.busad3 {
    background-image: url(https://regalia.adnuntius.com/label/businessad/b3.jpg);
  }

  &.busad4 {
    background-image: url(https://regalia.adnuntius.com/label/businessad/b4.jpg);
  }

  &.ipl {
    background-image: url(https://regalia.adnuntius.com/label/ipl/bg-ipl.png);
  }

  &.epps {
    background-image: url(https://regalia.adnuntius.com/label/epp/eppsteiner_background.jpg);
  }

  &.russ {
    background-image: url(https://regalia.adnuntius.com/label/russmedia/bg.jpg);
  }

  &.gamma {
    background-image: url(https://regalia.adnuntius.com/label/gamma/gamma-singapore-skyline.jpg);
  }

  &.summit {
    background-image: url(https://regalia.adnuntius.com/label/summit/ADNUNTIUS-Background-image-1.jpg);
  }

  &.philstar {
    background-image: url(https://regalia.adnuntius.com/label/philstar/adn-bg.jpg);
  }

  &.burda {
    background-image: url(https://regalia.adnuntius.com/label/burda/kam-cover.jpg);
  }

  &.az {
    background-image: url(https://regalia.adnuntius.com/label/az/az-background.png);
  }

  &.reflex {
    background-image: url(https://regalia.adnuntius.com/label/reflex/Background_reflex.jpg);
  }

  &.foyster {
    background: url(https://regalia.adnuntius.com/label/foyster/fm.png) repeat;
  }

  &.mpfr {
    background-image: url(https://regalia.adnuntius.com/label/mp-france/MP-France-Background.png);
  }

  &.ais {
    background-image: url(https://regalia.adnuntius.com/label/ais/THAN_1920x1080-BG.jpeg);
  }

  &.atten {
    background-image: url(https://regalia.adnuntius.com/label/atten/bg-full.jpg)
  }

  &.mopo3 {
    background-image: url(https://regalia.adnuntius.com/label/mopo/bg.jpg);
  }

  &.confi {
    background-image: url(https://regalia.adnuntius.com/label/confi/adn-bg.jpg);
  }
}

/** copied from bootstrap 3 **/
.thumbnail {
  display: block;
  position: relative;
  padding: 4px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.thumbnail {
  transition: none;

  .caption {
    word-wrap: break-word;
    font-size: 11px;
  }

  .upload {
    border: 2px dashed #ddd;
    line-height: 1.1;
    text-align: center;
    height: 10vw;
    margin-bottom: 4px;

    &.hovering {
      border-color: $info;
    }

    p {
      margin: 0;
    }

    .drop-zone {
      padding: 3vw 4px 4px;
      height: 100%;
    }

    .caption {
      margin-top: 10px;
    }
  }

  .asset {
    display: block;
    text-align: center;
    line-height: 10vw;
    max-height: 10vw;

    .htmlAssetContainer {
      display: table-cell;
      line-height: 1.2em;
      height: 10vw;
      vertical-align: middle;
      width: 300px;
    }

    img {
      max-height: 10vw;
      display: inline-block;
      max-width: 100%;
    }

    > object, > object > object {
      max-width: 100%;
      max-height: 10vw;
      line-height: 1;
    }
  }
}

@media (min-width: 992px) {

  .assets {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-left: -10px;
    margin-right: -10px;

    .thumbnail {
      flex-basis: calc(#{$colWidthLg} - 20px);
      margin-left: 10px;
      margin-right: 10px;
      min-width: 180px;

      .assetFileName {
        width: calc(#{$colWidthLg} - 20px) px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.active {
        background-color: #eee;
      }
    }

    &.assets-sm .thumbnail {
      flex-basis: calc(#{$colWidthSm} - 20px);

      .asset {
        line-height: 5vw;
        max-height: 5vw;

        > img, > object, > object > object {
          max-height: 5vw;
        }
      }
    }

    &.assets-lg .thumbnail {
      flex-basis: calc(#{$colWidthLg} - 20px);

      .asset {
        line-height: 15vw;
        max-height: 15vw;

        > img, > object, > object > object {
          max-height: 15vw;
        }
      }
    }
  }
}

thead th {
  font-weight: normal;
}

.filterOption {
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #ddd;
  text-decoration: none;
  padding: 3px 5px;
  margin-right: 5px;
  background-color: #f9f9f9;

  &:hover {
    cursor: pointer;
  }

  &:hover:not(.filterOptionSelected) {
    background-color: #e9e9e9;
  }

  &.filterOptionSelected {
    border-color: $success;
    background-color: lighten($success, 60%);
    text-decoration: none;

    .fa {
      color: $success;
    }
  }
}


.is-invalid {
  input, select, .drop-zone {
    background-color: lighten($danger, 45%);
  }
}

input, select, .drop-zone {
  &.is-invalid {
    background-color: lighten($danger, 45%);
  }
}


.btn-group-xs > .btn, .btn-xs {
  padding  : .25rem .4rem;
  font-size  : .75rem;
  line-height  : .35;
  border-radius : .2rem;
}

.modal-header {
  background-color: #eee;
}

.loading-overlay-parent {
  position: relative;
}

.previewBorder {
  border: 1px solid #bbb;
}

.micon {
  border-radius: 80px;
  width: 66px;
  margin-top: 15px;
  padding: 3px 0;
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: center;
  font-size: 40px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
}

.loading-overlay {
  cursor: wait;
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
}

ul.noIndentation {
  margin-left: 5px;
  margin-right: 0;
  padding-left: 10px;
  padding-right: 0;
}

ngb-alert {
  *:last-child {
    margin-bottom: 0;
  }
}

// Theme customization
@import "theme/theme";

/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

//$white: #fff;
//$gray-100: #f8f9fa;
//$gray-200: #e9ecef;
//$gray-300: #dee2e6;
//$gray-400: #ced4da;
//$gray-500: #adb5bd;
//$gray-600: #868e96;
//$gray-700: #495057;
//$gray-800: #343a40;
//$gray-900: #212529;
//$black: #000;
//
//$blue: #0073dd;
//$indigo: #6610f2;
//$purple: #6f42c1;
//$pink: #e83e8c;
//$red: #dc3545;
//$orange: #fd7e14;
//$yellow: #ffc107;
//$green: #28a745;
//$teal: #20c997;
//$cyan: #17a2b8;
//
//$theme-colors: (
//  primary: $blue,
//  secondary: $gray-600,
//  success: $green,
//  info: $cyan,
//  warning: $yellow,
//  danger: $red,
//  light: $gray-100,
//  dark: $gray-800,
//);

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$link-decoration: none;
$link-hover-decoration: underline;

$container-max-widths: (
  sm: 440px,
  md: 620px,
  lg: 860px,
  xl: 1040px,
  xxl: 1220px,
);

$badge-font-size: 0.9em;

$popover-max-width: 330px;
$btn-focus-width: 0px;

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, Calibri, 'Calibri Light', sans-serif;
$font-size-base: "14px";
$btn-font-size: "14px";
$font-size-root: "14px";

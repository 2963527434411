/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

@import "solarized";

@mixin theme( $headerBg, $headerBgSecond,
$userMiconBg, $navLinkText, $navLinkTextHover, $navLinkTextHoverBg, $fontFamily, $fontHeadingFamily, $fontColor, $signInContainerBg ) {

  app-root {
    .signInContainer {
      .signInFlexContainer {
        .frontPageContent {
          background-color: $signInContainerBg;
          border-color: darken($signInContainerBg, 7%)
        }
      }
    }
  }

  thead th a {
    color: $fontColor;
  }

  app-root {
    font-family: $fontFamily;
    color: $fontColor;

    h1, h2, h3, h4, h5, h6 {
      font-family: $fontHeadingFamily;
    }
  }

  .appContainer {
    background-color: lighten($base1, 35%);

    .mainContainer {
      border-left: 1px solid lighten($base1, 25%);
      border-right: 1px solid lighten($base1, 25%);
    }
  }

  .micon {
    background-color: $userMiconBg;
  }

  .signInContainer {
    footer {
      background-image: none;
    }
  }

  header.app, footer {
    background-color: $headerBg;
    background-image: linear-gradient(to right, $headerBg, $headerBgSecond);
    color: $navLinkText;

    .menuText {
      color: $navLinkText;
    }

    .titleNavContainer, .languageContainer {
      li {
        margin-left: 5px;

        > a {
          color: $navLinkText;
        }

        > a:hover, & > a:focus {
          color: $navLinkTextHover;
          background-color: $navLinkTextHoverBg;
        }

        & > .active {
          color: $navLinkTextHover;
          background-color: $navLinkTextHoverBg;
        }
      }
    }

    .sessionInfo > a, .context-menu > a {
      color: $navLinkText;
    }
  }

  .dialogAddStuff, .adnDialog .modal-header {
    background-color: $headerBg;

    .modal-title {
      color: $base1;
    }
  }
}

$calibriFontFamily: Calibri, 'Calibri Light', "Helvetica Neue", Helvetica, Arial, Calibri, 'Calibri Light', sans-serif;
$robotoFontFamily: "Roboto", "Helvetica Neue", Helvetica, Arial, Calibri, 'Calibri Light', sans-serif;
$robotoSlabFontFamily: "Roboto Slab", "Roboto", "Helvetica Neue", Helvetica, Arial, Calibri, 'Calibri Light', sans-serif;
$robotoCondensedFontFamily: "Roboto Condensed", "Roboto", "Helvetica Neue", Helvetica, Arial, Calibri, 'Calibri Light', sans-serif;
$montserrat: Montserrat, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
$openSans: "Open Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;

body, body[data-user-theme="solarized.dark"] {
  @include theme(
    $headerBg: $base03, $headerBgSecond: $base03,
    $userMiconBg: $base03, $navLinkText: $base1, $navLinkTextHover: $base1-highlight, $navLinkTextHoverBg: $base02,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: #f0f0f0
  );
}

body, body[data-user-theme="oneid"] {
  @include theme(
    $headerBg: #353631, $headerBgSecond: #353631,
    $userMiconBg: #353631, $navLinkText: $base1, $navLinkTextHover: $base1-highlight, $navLinkTextHoverBg: $base02,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: #f0f0f0
  );
}

body[data-user-theme="busad"] {
  @include theme(
    $headerBg: #7ab51d, $headerBgSecond: #7ab51d,
    $userMiconBg: #7ab51d, $navLinkText: #f2f2f2, $navLinkTextHover: #fff, $navLinkTextHoverBg: #8bd90f,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: #f0f0f0
  );
}

body[data-user-theme="immb"] {
  @include theme(
    $headerBg: $base03, $headerBgSecond: $base03,
    $userMiconBg: $base03, $navLinkText: $base1, $navLinkTextHover: $base1-highlight, $navLinkTextHoverBg: $base02,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: transparent
  );
}

body[data-user-theme="abendzeitung"] {
  @include theme(
    $headerBg: #fff, $headerBgSecond: #fff,
    $userMiconBg: #2e4052, $navLinkText: #000, $navLinkTextHover: #e2021b, $navLinkTextHoverBg: #fff,
    $fontFamily: $robotoFontFamily, $fontHeadingFamily: $robotoSlabFontFamily, $fontColor: #333, $signInContainerBg: #fff
  );
}

body[data-user-theme="gamma"] {
  @include theme(
    $headerBg: #fff, $headerBgSecond: #fff,
    $userMiconBg: #2e4052, $navLinkText: #000, $navLinkTextHover: #fff, $navLinkTextHoverBg: #0877bc,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: #f0f0f0
  );
}

body[data-user-theme="pt"] {
  @include theme(
    $headerBg: #43c4d8, $headerBgSecond: #6567af,
    $userMiconBg: #2e4052, $navLinkText: #fff, $navLinkTextHover: #fff, $navLinkTextHoverBg: #0877bc,
    $fontFamily: $robotoFontFamily, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: transparent
  );
}

body[data-user-theme="russ"] {
  @include theme(
    $headerBg: $base03, $headerBgSecond: $base03,
    $userMiconBg: $base03, $navLinkText: $base1, $navLinkTextHover: #fff, $navLinkTextHoverBg: $base02,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: #f0f0f0
  );
}

body[data-user-theme="bm"] {
  @include theme(
    $headerBg: #59BBD7, $headerBgSecond: #59BBD7,
    $userMiconBg: $base03, $navLinkText: #fff, $navLinkTextHover: #fff, $navLinkTextHoverBg: $base02,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #0A173B, $signInContainerBg: #59bbd7
  );
}

body[data-user-theme="reflex"] {
  @include theme(
    $headerBg: #f4f4f4, $headerBgSecond: #f4f4f4,
    $userMiconBg: #2e4052, $navLinkText: #333, $navLinkTextHover: #000, $navLinkTextHoverBg: #c5c4c5,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: #f0f0f0
  );
}

body[data-user-theme="ipl"] {
  @include theme(
    $headerBg: #353535, $headerBgSecond: #353535,
    $userMiconBg: #2e4052, $navLinkText: #fff, $navLinkTextHover: #353535, $navLinkTextHoverBg: #fff,
    $fontFamily: $calibriFontFamily, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: #f0f0f0
  );
}

body[data-user-theme="atten"] {
  @include theme(
    $headerBg: #454545, $headerBgSecond: #454545,
    $userMiconBg: #2e4052, $navLinkText: #fff, $navLinkTextHover: #e2021b, $navLinkTextHoverBg: #454545,
    $fontFamily: $robotoCondensedFontFamily, $fontHeadingFamily: $robotoCondensedFontFamily, $fontColor: #454545, $signInContainerBg: #f0f0f0
  );

  .uiLogo {
    position: relative;
    top: -2px;
  }
}

$hamburgerRed: #e30613;
body[data-user-theme="hamburger"] {
  @include theme(
    $headerBg: #fff, $headerBgSecond: #fff,
    $userMiconBg: #2e4052, $navLinkText: #222, $navLinkTextHover: #fff, $navLinkTextHoverBg: $hamburgerRed,
    $fontFamily: $montserrat, $fontHeadingFamily: $montserrat, $fontColor: #222, $signInContainerBg: #f0f0f0
  );
}

$mediasquareRed: #ff3333;
body[data-user-theme="mediasquare"] {
  @include theme(
    $headerBg: #fff, $headerBgSecond: #fff,
    $userMiconBg: #2e4052, $navLinkText: #222, $navLinkTextHover: #fff, $navLinkTextHoverBg: $mediasquareRed,
    $fontFamily: $montserrat, $fontHeadingFamily: $montserrat, $fontColor: #222, $signInContainerBg: #f0f0f0
  );
}

body[data-user-theme="adrev"] {
  @include theme(
    $headerBg: #fff, $headerBgSecond: #fff,
    $userMiconBg: #2e4052, $navLinkText: #333, $navLinkTextHover: #3a3a3a, $navLinkTextHoverBg: #c5c4c5,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #3a3a3a, $signInContainerBg: #fff
  );
}

body[data-user-theme="confidencial"] {
  @include theme(
    $headerBg: #00264b, $headerBgSecond: #00264b,
    $userMiconBg: #2e4052, $navLinkText: #e5e8e8, $navLinkTextHover: #073642, $navLinkTextHoverBg: #fc9900,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #3a3a3a, $signInContainerBg: #fff
  );
}

body[data-user-theme="burda"] {
  @include theme(
    $headerBg: #393939, $headerBgSecond: #393939,
    $userMiconBg: #2e4052, $navLinkText: #EDEDE9, $navLinkTextHover: #f8f8f6, $navLinkTextHoverBg: #575757,
    $fontFamily: $openSans, $fontHeadingFamily: $openSans, $fontColor: #393939, $signInContainerBg: #fff
  );
}

$foysterBg: #992c7b;
body[data-user-theme="foyster"] {
  @include theme(
    $headerBg: $foysterBg, $headerBgSecond: $foysterBg,
    $userMiconBg: #2e4052, $navLinkText: #EDEDE9, $navLinkTextHover: #f8f8f6, $navLinkTextHoverBg: #333,
    $fontFamily: $openSans, $fontHeadingFamily: $openSans, $fontColor: #393939, $signInContainerBg: #fff
  );
}

body[data-user-theme="calt"] {
  @include theme(
    $headerBg: #1F4E5E, $headerBgSecond: #1F4E5E,
    $userMiconBg: #2e4052, $navLinkText: #93A1A1, $navLinkTextHover: #E5E8E8, $navLinkTextHoverBg: #073642,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #3e3e3e, $signInContainerBg: #f0f0f0
  );
}

body[data-user-theme="philstar"] {
  @include theme(
    $headerBg: #178dbf, $headerBgSecond: #178dbf,
    $userMiconBg: #2e4052, $navLinkText: #93A1A1, $navLinkTextHover: #E5E8E8, $navLinkTextHoverBg: #fff000,
    $fontFamily: $robotoFontFamily, $fontHeadingFamily: $robotoFontFamily, $fontColor: #3e3e3e, $signInContainerBg: #fff
  );
}

$LibSansNarrow: "Liberation-Sans-Narrow", Arial, "Helvetica Neue", Helvetica, sans-serif;
body[data-user-theme="epp"] {
  @include theme(
    $headerBg: #11297e, $headerBgSecond: #11297e,
    $userMiconBg: #2e4052, $navLinkText: #fff, $navLinkTextHover: #fff, $navLinkTextHoverBg: #007fff,
    $fontFamily: $LibSansNarrow, $fontHeadingFamily: $LibSansNarrow, $fontColor: #695f5c, $signInContainerBg: #fff
  );
}

body[data-user-theme="fjell"] {
  @include theme(
    $headerBg: #2f5477, $headerBgSecond: #2f5477,
    $userMiconBg: #2e4052, $navLinkText: #fff, $navLinkTextHover: #fff, $navLinkTextHoverBg: #126371,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #3a3a3a, $signInContainerBg: #fff
  );
}

$NunitoSans: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$infrankenRed: #e2001a;
$infrankenGrey: #ddd;
body[data-user-theme="infranken"] {
  @include theme(
    $headerBg: #fff, $headerBgSecond: #fff,
    $userMiconBg: #404a50, $navLinkText: #404a50, $navLinkTextHover: #fff, $navLinkTextHoverBg: #404a50,
    $fontFamily: $NunitoSans, $fontHeadingFamily: $NunitoSans, $fontColor: #404a50, $signInContainerBg: #fff
  );
}

body[data-user-theme="infranken"] {
  .uiLogo {
    position: relative;
    top: 4px;
  }

  a:not(.btn), .btn-link {
    color: $infrankenRed;

    &:hover {
      color: darken($infrankenRed, 5%);
    }
  }

  .postMessageChoices {
    img {
      max-width: 100%;
    }
  }

  .alert-info {
    background-color: $infrankenGrey;
    border-color: darken($infrankenGrey, 5%);
    color: darken($infrankenGrey, 75%);
  }

  .btn-primary:not(.btnPayment), .btnSaveCampaign, .btnNew {
    background-color: $infrankenRed;
    color: #fff;
    border-color: darken($infrankenRed, 5%);

    &:hover {
      background-color: darken($infrankenRed, 2%)
    }
  }

  #app.auth-shell-container footer {
    color: #666;
  }

  footer .mx-auto > div:first-child {
    max-width: 450px;
    text-align: left;
    margin-bottom: 10px;
  }
}

$meinestadtYellow: #FFE857;
body[data-user-theme="meinestadt"] {
  @include theme(
    $headerBg: #fff, $headerBgSecond: #fff,
    $userMiconBg: #404a50, $navLinkText: #333, $navLinkTextHover: #333, $navLinkTextHoverBg: $meinestadtYellow,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: #fff
  );
}

body[data-user-theme="meinestadt"] {
  .uiLogo {
    position: relative;
    top: 4px;
  }

  .anonLink {
    background-color: $meinestadtYellow;
    margin-top: -9px;
    padding: 8px;
    border-radius: 5px;

    a {
      color: #333;
      text-decoration: none !important;
      font-weight: bold;
    }
  }

  a:not(.btn), .btn-link {
    color: #333;
    text-decoration: underline;

    &:hover {
      color: darken(#333, 5%);
    }
  }

  .alert {
    color: #333;
  }

  .alert-info {
    background-color: #DAE7F1;
    border-color: #6DA0C7;
  }

  .alert-danger {
    background-color: #FBCFD8;
    border: #F14165;
  }

  .alert-warning {
    background-color: #FBE8CF;
    border: #F1A441;
  }

  .navbar-brand {
    position: relative;
    top: -5px;
  }

  .btn-primary:not(.btnPayment), .btnSaveCampaign, .btnNew {
    background-color: $meinestadtYellow;
    color: #000;
    font-weight: bold;
    border-color: darken($meinestadtYellow, 5%);

    &:hover {
      background-color: darken($meinestadtYellow, 2%)
    }
  }

  app-header {
    box-shadow: 0 4px 12px 0 rgba(0 0 0 / 4%), 0 7px 25px 0 rgba(0 0 0 / 4%);
    position: relative;
  }

  .signInContainer {
    footer .mx-auto > div:first-child {
      max-width: 550px;
      margin-bottom: 10px;
    }

    footer {
      p {
        margin-bottom: 10px;
      }

      a {
        color: white !important;
        font-weight: bold;
      }
    }
  }
}

body[data-user-theme="hallingdolen"] {
  @include theme(
    $headerBg: #005766, $headerBgSecond: #005766,
    $userMiconBg: #7ab51d, $navLinkText: #f2f2f2, $navLinkTextHover: #fff, $navLinkTextHoverBg: #126371,
    $fontFamily: $font-family-sans-serif, $fontHeadingFamily: $font-family-sans-serif, $fontColor: #333, $signInContainerBg: #f0f0f0
  );
}

body[data-user-theme="hallingdolen"] {
  .uiLogo {
    width: 160px;
  }
}

body[data-user-theme="foyster"] {
  .btnNew {
    background-color: $foysterBg;
    color: #fff;
    border-color: darken($foysterBg, 5%);

    &:hover {
      background-color: darken($foysterBg, 2%)
    }
  }

  #app.signInContainer {
    footer {
      color: #333;
    }
  }
}

body[data-user-theme="hamburger"] {
  .anonLink {
    background-color: $hamburgerRed;
    margin-top: -9px;
    padding: 8px;

    a {
      color: white;
    }
  }

  .frontPageContent {
    max-width: 750px;
  }

  .btnNew {
    background-color: $hamburgerRed;
    color: #fff;
    border-color: darken($hamburgerRed, 5%);

    &:hover {
      background-color: darken($hamburgerRed, 2%)
    }
  }
}

body[data-user-theme="mediasquare"] {
  .anonLink {
    background-color: $mediasquareRed;
    margin-top: -9px;
    padding: 8px;

    a {
      color: white;
    }
  }

  .frontPageContent {
    max-width: 750px;
  }

  .btnNew {
    background-color: $mediasquareRed;
    color: #fff;
    border-color: darken($mediasquareRed, 5%);

    &:hover {
      background-color: darken($mediasquareRed, 2%)
    }
  }
}

body[data-user-theme="ipl"] {
  .appContainer {
    background-image: url(https://regalia.adnuntius.com/label/ipl/bg-ipl.png);
  }
  .appContainer th {
    background-color: #C58F32 !important;
    color: #fff !important;
  }
  .appContainer .listRowHeader th a {
    color: #fff !important;
  }
}

body[data-user-theme="abendzeitung"] {
  header {
    border-bottom: 2px solid #fafafa;

    .navbarLinks {
      a {
        font-family: $robotoSlabFontFamily;
        font-size: 16px;
        padding: 2px 6px;
        margin: 5px 12px;
      }
    }
  }

  .appContainer {
    background-color: #fff;

    .mainContainer {
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
    }
  }

  footer {
    border-top: 2px solid #fafafa;
  }
}
